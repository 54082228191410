import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>Oops this page does not exist....yet.</p>
  </Layout>
)

export default NotFoundPage
